import type { AuctionCar } from '@autobid/ui/types/Car'
import { getSingleAuctionQueryFields } from '@autobid/ui/utils/auctions/getSingleAuctionQueryFields'
import type { CarQueryProps } from '@autobid/ui/utils/car/query/getQueryField'
import { getQueryField } from '@autobid/ui/utils/car/query/getQueryField'

export const detailsMissingFieldsInfo = (
  locale: string,
  data?: Partial<AuctionCar> | null
) => {
  const potentialMissingDetailsFields = [
    'auctionId',
    'auctionNumber',
    'dataAddition',
    'supplier',
    'publicationInfo'
  ]

  const missingFields = potentialMissingDetailsFields
    .filter((el) => !data || !(el in data))
    .map((el) => getQueryField(el, locale))
    .join(' ')

  return {
    missingFields
  }
}

export const getDetailsQuery = ({
  lang,
  locales,
  currentData,
  ignoreCache,
  omitAuction
}: CarQueryProps) => {
  let itemDataFields = `
    id
    appId
    slug
    catalogNumber
    ${getQueryField('auctionId')}
    taxInformation
    name
    stage
    hasBid
    isWatcher
    ${getQueryField('dataAddition')}
    status {
      bmwBankFinancingAvailable
      bank11FinancingAvailable
      transportAvailable
    }
    ${getQueryField('supplier')}
    ${getQueryField('manufacturer')}
    price {
      start
      minimal
      current
    }
    additionalInformation {
      itemLocationCode
      itemLocationCountry {
        name
        vatRate
      }
    }
    auctionStartDate
    auctionNumber
    imageGroups {
      itemMainImageSubgroup {
        links {
          hd
        }
        category
        description {
          categoryLabel
        }
      }
    }
    category {
      id
      name
    }
    hasNote
    equipments
    ${getQueryField('publicationInfo')}
    isHighestBidder
  `

  if (currentData && !ignoreCache) {
    const { missingFields } = detailsMissingFieldsInfo(lang, currentData)

    itemDataFields = missingFields ?? ''
  }

  return `
  query details($id: Int!, $lang: String! = "DE") {
    singleItem(params: {id: $id, lang: $lang}) {
      ${currentData && 'pagination' in currentData ? '' : 'metadata'}
      item {
        ${itemDataFields ? `itemData { ${itemDataFields}}` : ''}
        itemDetails {
          headlineDescription
          generalDescriptionHeader
          information
          documents
          extras
          specialExtras {
            extras
          }
          seats
          mainImageSubgroup
          imagePanelInstrument
          imageAdditionalDetails
          imageBeautyDetails
          demurrageFee
          wheels
          secondWheels
          imagesExterior
          imagesInterior
          imagesWindshield
          foundDefects
          missingParts
          gallery
          generalInformation
          goft
          accidentPredamages
          startPrice
          engineTransmissionTurbo
          accidentInfo
        }
        ${
          (omitAuction || currentData) && !ignoreCache
            ? ''
            : `auctionData { ${getSingleAuctionQueryFields(lang, locales)} }`
        }
      }
    }
  }`
}
