import { useCarsStore } from '@autobid/nuxt-pinia-store/store/useCarsStore'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import type { QueryClient } from '@tanstack/vue-query'
import { useQueryClient } from '@tanstack/vue-query'
import type {
  AuctionCar,
  BarometerAuction,
  BarometerCar,
  BarometerResp,
  DetailsCar,
  DetailsResp,
  PrefetchOptions
} from '@autobid/ui/types/Car'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { generateImageSizesFromObject } from '@autobid/ui/utils/car/generateImageSizes'
import type { CarsListMetaData } from '@autobid/ui/types/components/CarsList'
import type { SingleAuctionResp } from '@autobid/ui/types/components/AuctionsData'
import { getUseAuctionTanstackKey } from '@autobid/ui/composables/useAuction'
import { prepareFetchAuction } from '@autobid/ui/composables/useGetAuctionFetcher'
import { getDetailsQuery } from '@autobid/ui/utils/car/query/getDetailsQuery'
import { getBarometerQuery } from '@autobid/ui/utils/car/query/getBarometerQuery'
import { getInAuctionCar } from '@autobid/ui/utils/car/getInAuctionCar'

export const BAROMETER_QUERY_BID_AMOUNT = 11

/**
 * @returns Already prefetched car
 */
export const useGetCar = () => {
  const { carsData } = storeToRefs(useCarsStore())

  const getCar = (carId?: number) => {
    if (carId === undefined) return null

    return carsData.value?.items?.[carId]
  }

  return { getCar }
}

type CurrentCarProps = {
  type: 'current'
  auctionId: number
  auctionSeparated?: boolean
  carId?: never
}

type BarometerProps = {
  type: 'barometer'
  carId: number
  auctionId?: never
}

type DetailsProps = {
  type: 'details'
  carId: number
  auctionId?: never
}

type CarError = {
  id: number | null
  message: string | null
  error_code: number
  type?: 'details'
}

type UseCar = {
  data: ComputedRef<BarometerCar | DetailsCar>
  error: ComputedRef<CarError>
  id: Ref<number>
  isFetching: any
  fetchCar: (options?: PrefetchOptions) => Promise<void>
  prefetchCar: (options?: PrefetchOptions) => Promise<void>
}
type UseBarometerCar = UseCar & { data: ComputedRef<BarometerCar> }
type UseDetailsCar = UseCar & { data: ComputedRef<DetailsCar> }

export function useCar(
  props: CurrentCarProps,
  _queryClient?: QueryClient
): UseBarometerCar
// eslint-disable-next-line no-redeclare
export function useCar(
  props: BarometerProps,
  _queryClient?: QueryClient
): UseBarometerCar
// eslint-disable-next-line no-redeclare
export function useCar(
  props: DetailsProps,
  _queryClient?: QueryClient
): UseDetailsCar
// eslint-disable-next-line no-redeclare
export function useCar(
  props: CurrentCarProps | BarometerProps | DetailsProps,
  _queryClient?: QueryClient
) {
  const headers = useRequestHeaders(['cookie'])
  const queryClient = _queryClient ?? useQueryClient()
  const { $customFetch } = useCustomFetch()
  const { getCar } = useGetCar()
  const { loadingNewCars, carsData, noFilterMetadata } = storeToRefs(
    useCarsStore()
  )
  const id = ref('carId' in props && props.carId ? props.carId : undefined)
  const isFetching = useState(
    `${props.type}-${'auctionId' in props ? props.auctionId : props.carId}`
  )
  const getCarData = () => {
    if (props.type === 'current') {
      return getInAuctionCar(carsData.value?.items, props.auctionId)
    }

    return id.value ? getCar(id.value) : null
  }
  const data = computed(() => {
    const carData = getCarData()

    const isComplete =
      carData &&
      (props.type === 'details'
        ? 'hasDetails' in carData
        : 'hasBarometer' in carData)

    if (!isComplete) return null

    return props.type === 'details'
      ? (carData as DetailsCar)
      : (carData as BarometerCar)
  })
  const error = computed(() => {
    if (!id.value) return null

    const err = carsData.value?.metadata?.errors

    if (!err) return null

    if (!Array.isArray(err)) return err

    return err.find((el) => el.id === id.value)
  })
  const { locale, locales } = useI18n()

  const setMetadata = (
    counters?: CarsListMetaData['counters'],
    errors?: CarsListMetaData['errors']
  ) => {
    if (!counters && !errors) return

    if (counters) {
      noFilterMetadata.value = { counters }
    }

    carsData.value = {
      ...(carsData.value ?? {}),
      metadata: {
        errors,
        counters: counters ?? carsData.value?.metadata.counters
      }
    } as typeof carsData.value
  }

  const setAuctionData = (
    auctionId?: number,
    auctionData?: BarometerAuction
  ) => {
    if (!auctionId || !auctionData) return

    // assign auction data to suitable tanstack key
    const queryKey = getUseAuctionTanstackKey(auctionId, locale.value)

    const rawAuctionData: SingleAuctionResp = {
      data: {
        singleAuction: {
          item: auctionData
        }
      }
    }

    const preparedAuctionData = prepareFetchAuction(
      rawAuctionData,
      locale.value
    )

    queryClient.setQueryData(queryKey, preparedAuctionData)
  }

  const saveCar = (
    resp:
      | BarometerResp['data']['singleItem']
      | DetailsResp['data']['singleItem']
  ) => {
    if (resp.item.itemData?.imageGroups) {
      // only hd image is fetched - generate rest sizes
      resp.item.itemData.imageGroups = generateImageSizesFromObject(
        'hd',
        resp.item.itemData.imageGroups
      )
    }

    const respCar: BarometerCar | DetailsCar = {
      ...('itemDetails' in resp.item
        ? {
            ...resp.item.itemData,
            hasDetails: 1,
            details: resp.item.itemDetails
          }
        : { ...resp.item.itemData, hasBarometer: 1 })
    }

    if (resp.metadata) {
      const { lastCatalogNumber, next, previous, total } = resp.metadata
      respCar.pagination = { lastCatalogNumber, next, previous, total }
    }

    const respCarId = id.value ?? resp.item.itemData.id
    const carData = {
      ...(carsData.value?.items?.[respCarId] ?? {}),
      ...respCar
    }

    carsData.value = {
      ...(carsData.value ?? { itemCount: 1, itemPageCount: 1 }),
      items: {
        ...(carsData.value?.items ?? {}),
        [respCarId]: carData
      } as Record<number, AuctionCar>,
      metadata: carsData.value?.metadata ?? {}
    }
  }

  const fetchCar = async (options?: PrefetchOptions) => {
    if (options?.id) {
      id.value = options.id
    }

    if (
      isFetching.value ||
      (props.type !== 'current' && isNaN(id.value)) ||
      (props.type === 'current' && isNaN(props.auctionId))
    ) {
      return
    }

    isFetching.value = true

    if (!options?.hideLoading) {
      loadingNewCars.value = true
    }

    const params = {
      ...(options ?? {}),
      lang: locale.value,
      locales: locales.value,
      currentData: getCarData()
    }

    const result = await $customFetch<BarometerResp | DetailsResp>(
      '/api/backend',
      {
        method: 'POST',
        headers: {
          ...(headers ?? {})
        },
        body: {
          queryApi: 'auctions',
          queryMethod: 'POST',
          queryUrl: '/api/v1/query',
          query:
            props.type === 'details'
              ? getDetailsQuery(params)
              : getBarometerQuery({
                  ...params,
                  auctionSeparated: !!(
                    'auctionSeparated' in props && props.auctionSeparated
                  ),
                  bidLimit: BAROMETER_QUERY_BID_AMOUNT
                }),
          variables: {
            ...(props.type === 'current'
              ? { auctionId: props.auctionId ?? options?.auctionId }
              : { id: id.value }),
            lang: locale.value
          }
        }
      }
    )

    if (!result || result.errors?.length || !result.data) {
      isFetching.value = false
      return
    }

    const hasItem = !!result.data.singleItem.item
    if (hasItem) {
      saveCar(result.data.singleItem)
    }

    const metadata = result.data.singleItem.metadata
    if (metadata) {
      const { counters } = metadata
      const errors =
        'errors' in metadata
          ? metadata.errors
          : [
              {
                id: metadata.id,
                message: metadata.message,
                error_code: metadata.error_code
              }
            ]

      setMetadata(options?.omitSettingCounters ? undefined : counters, errors)
    }

    const auctionData = result.data.singleItem.item?.auctionData
      ? result.data.singleItem.item?.auctionData
      : 'singleAuction' in result.data
      ? result.data.singleAuction?.item
      : null

    if (auctionData) {
      setAuctionData(auctionData.id, auctionData)
    }

    if (!options?.hideLoading) {
      loadingNewCars.value = false
    }

    isFetching.value = false
  }

  const prefetchCar = async (options?: PrefetchOptions) => {
    if (data.value) return

    await fetchCar(options)
  }

  return { data, error, id, isFetching, fetchCar, prefetchCar }
}
