import type { AuctionCar } from '@autobid/ui/types/Car'

export const getInAuctionCar = (
  cars: Record<number, AuctionCar> | undefined,
  auctionId: number
) => {
  return Object.values(cars ?? {}).find(
    (car) =>
      car.auctionId === auctionId &&
      (car.stage === 'IN_AUCTION' ||
        (car.stage === 'FINISHED' && car.loadingNextCar))
  )
}
